<template>
  <v-card
    rounded="lg"
    color="transparent"
    height="100%"
    class="d-flex flex-column"
    >
    <v-card-title
      class="py-1 primary white--text lower-opacity"
      >
      Actividades
    </v-card-title>

    <v-card-text
      style="max-height: 300px; overflow: auto"
      class="pt-6 lower-background-opacity grow"
      >
      <v-row>
        <v-col
          cols="5"
          >
          <v-autocomplete
            outlined
            :items="workTypes"
            label="Tipo de trabajo"
            :value="rollout.workType"
            readonly
            hide-details
            dense
            style="background-color: white"
            class="mb-3"
            append-icon=""
            ></v-autocomplete>
        </v-col>
      </v-row>
      <template
        v-for="activity in rollout.huaweiActivities"
        >
        <v-row>
          <v-col
            cols="5"
            class="pr-0 pb-0"
            >
            <v-text-field
              outlined
              label="Categoría"
              :value="activity.category"
              readonly
              hide-details
              dense
              style="background-color: white"
              class="mb-3"
              ></v-text-field>
          </v-col>

          <v-col
            cols="5"
            class="pr-0 pb-0"
            >
            <v-text-field
              outlined
              label="Descripción"
              :value="activity.description"
              readonly
              hide-details
              dense
              style="background-color: white"
              class="mb-3"
              ></v-text-field>
          </v-col>

          <v-col
            cols="2"
            class="pb-0"
            >
            <v-text-field
              outlined
              label="Cantidad"
              :value="activity.quantity"
              readonly
              hide-details
              dense
              style="background-color: white"
              class="mb-3"
              ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-card-text>

    <v-card-actions
      class="lower-background-opacity"
      style="gap: 8px"
      >
      <v-spacer></v-spacer>
      <close
        v-if="currentUser && (['shibui', 'rollout', 'management'].includes(currentUser.currentRoleName)) && rollout && rollout.huaweiActivities && rollout.huaweiActivities.length"
        :disabled="!!rollout.huaweiActivities[0].closed"
        activity="huawei"
        :id="rollout.id"
        @reload="reload"
        ></close>

      <huawei-form
        :key="band"
        :rollout="rollout"
        @reload="reload"
        ></huawei-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

import HuaweiForm from './Dialog'
import Close from '@/components/sites/main/activities/Close'

export default {
  data: () => ({
    band: 0,
    workTypes: ['Implementación', 'Power', 'Clean Up', 'TSS'],
  }),

  props: {
    rollout: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    reload () {
      this.band += 1
      this.$emit('reload', this.rollout.id)
    }
  },

  components: { HuaweiForm, Close }
}
</script>
